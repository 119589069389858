.react-toggle-track {
  background-color: #FF0000;
}

.react-toggle:hover:not(.react-toggle--checked) .react-toggle-track {
  background-color: #CC0000;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: none;
}