.input-phone-number {
    input, button {
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
        background-color: transparent !important;
        font-size: 16px !important; // Increase text size
    }

    input {
        width: 100%;
    }
}