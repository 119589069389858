.container {
    display: flex;
}

.navbar {
    flex: 0 0 250px; 
    height: 100vh;
}

@media screen and (max-width: 1200px) {
    .navbar {
        flex: 0 0 20px;
    }
  }

.navbar ul {
    list-style-type: none;
    padding: 0;
}

.navbar ul li a {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: black;
}


