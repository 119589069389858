@property --var1 {
    syntax: "<color>";
    inherits: true;
    initial-value: transparent;
}

@property --var2 {
    syntax: "<color>";
    inherits: true;
    initial-value: transparent;
}


.scroll {
    transition: --var1 .5s, --var2 .5s;
}

.scroll:hover {
    --var1: #484c9c;
    --var2: #FFFFFF;
}

.scroll::-webkit-scrollbar {
    background: var(--var2);
    width: 8px;
    height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
    background: var(--var1);
    border-radius: 8px;
}
