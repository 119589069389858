@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: garetRegular;
  src: url("./content/font/Garet-Regular.ttf");
}

@font-face {
  font-family: garetBold;
  src: url("./content/font/Garet-Bold.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: garetRegular, sans-serif;
  font-weight: bold;
}

:root {
  --primary: #282424;
  --white: #f5f5f5;
  --green: #7ed957;
  --red: #ff5d5d;
  --lightgray: #e5e5e5;
}

body {
  background-color: white;
  padding: 0;
  
}

body.dark-mode {
  background-color: #252525;

}

.font-bold {
  font-family: garetBold;
}

/* Layouts gerais */

.input {
  @apply w-full px-4 py-2 border-none bg-[var(--lightgray)] text-black rounded-[25px] outline-[var(--primary)]
}

.small-button {
  @apply px-6 py-2 rounded-[10px] border-[transparent] border-[2px] flex justify-between items-center text-white cursor-pointer gap-4
}

.small-button:hover {
  @apply border-[var(--black)]
}

.big-button {
  @apply px-4 seis:px-8 h-[6rem] rounded-[15px] border-[transparent] border-[3px] text-white cursor-pointer 
}

.big-button.style1 {
  @apply flex mil:flex-col mil:justify-center mil:items-start seis:justify-between seis:items-center seis:flex-row flex-col justify-center overflow-hidden whitespace-nowrap
}

.big-button.style2 {
  @apply flex justify-between items-center overflow-hidden whitespace-nowrap
}

.big-button:hover {
  @apply border-[var(--black)]
}

.filter {
  @apply w-[14rem] px-2 py-1 bg-[var(--lightgray)] text-black rounded-[5px] border-[1px] border-[transparent] 
}

.filter:focus {
  @apply border-[var(--black)]
}

.mandatory::before {
  content: '* ';
  color: var(--red);
}

input[type="password"]::-ms-reveal {
  display: none;
}

/* ScrollBar */

/* Handle */
.my-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 100vw;
}

/* Handle */
.my-scrollbar::-webkit-scrollbar-thumb:active {
  background-color: #2f00ff;
  border-radius: 100vw;
}

/* Track */
.my-scrollbar::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 100vw;
}

.my-scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: .7rem;
}
