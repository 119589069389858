
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .loader {
    opacity: 1;
    width: 110px;
    aspect-ratio: 1;
    position: relative;
    animation: l1 1s infinite;;
    border-radius: 50% 50% 50% 50%;
    box-shadow: 0 0 0 0 #0004;
  }
  
  @keyframes l1 {
      100% {box-shadow: 0 0 0 110px #0000}
  }